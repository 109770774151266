import React from "react";
import slugify from "./slugify";

function parsePolygonString(polygonString) {
  if (!polygonString || typeof polygonString !== 'string') {
    return null;
  }

  try {
    const parsedArray = JSON.parse(polygonString);

    if (!Array.isArray(parsedArray)) {
      return null;
    }

    const coordinates = parsedArray[0].map(coord => ({
      lat: Number(coord[1]),
      lng: Number(coord[0]),
    }));

    return coordinates;
  } catch (error) {
    console.error('Error parsing polygonString:', error);
    return null;
  }
}
const colors = [
  "#020202",
  "#AF4222",
  "#E6CB5C",
  "#372C2F",
  "#DB6642",
  "#FFE45C",
  "#468A7F",
  "#F8E4D2"
]
const tableOptions = [
  {
    label: 'treaties',
    id: 0,
    table: 'tblNbzH8fyCHl9Uak',
    type: 'polygon'
  },
  {
    label: 'communities',
    id: 2,
    table: 'tblA1YdPobD3JN7iG',
    type: 'marker'
  },
  {
    label: 'territories',
    id: 3,
    table: 'tblD4d5rF383Bcbil',
    type: 'polygon'
  },
  {
    label: 'schools',
    id: 4,
    table: 'tblDUmxXXDiJcKcvv',
    type: 'marker'
  },
  {
    label: 'schools',
    id: 4,
    table: 'tblWfl46Mffq0IU46',
    type: 'marker'
  },
  {
    label: 'chats',
    id: 5,
    table: 'tblqKWImuUjMmUNU8',
    type: 'marker'
  },
  {
    label: 'businesses',
    id: 6,
    table: 'tblLvLuIFUJSVd3QS',
    type: 'marker'
  },
]

export function keyMergeMap(table, data, lang){
  
  if(table === 'tblNbzH8fyCHl9Uak') {
    return {
      name: data.Treaty_Name_EN,
      coordinates: parsePolygonString(data.Coordinates),
      color: colors[data.ID % colors.length],
      link_type: 'internal',
      link: `/${lang}/treaties/${data.Slug}`,
      description: data.Description_Alt,
      type: 'Treaties and Agreements'
    }
  } else if(table === 'tblA1YdPobD3JN7iG') {
    const syl = data.Syllabics ? ' ' + data.Syllabics : '';    
    return {
      name: data.Name + syl,
      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
      link_type: 'internal',
      link: `/${lang}/communities/${slugify(data.Name)}`,
      description: data.Description,
      subtitle: data.Pronunciation,
      type: 'Community'
    }
  } else if(table === 'tblD4d5rF383Bcbil') {   
    return {
      name: data.Name,
      coordinates: parsePolygonString(data.Coordinates),
      color: data.Color,
      link_type: 'internal',
      link: `/${lang}/territories/${data.Slug}`,
      description: data.Description,
      type: 'Traditional Territories'
    }
  } else if(table === 'tblDUmxXXDiJcKcvv' || table === 'tblWfl46Mffq0IU46') {
    const opDate = data.Operating_Dates
    const startDate = data.Start_Date ? data.Start_Date : 'Unknown'
    const endDate = data.End_Date ? data.End_Date : 'Unknown'
    const formDate = startDate === endDate ? null : `${startDate} - ${endDate}`;
    return {
      name: data.Name,
      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
      type: 'School',
      link_type: 'external',
      link: data.Link,
      image: data.Image,
      description: opDate || formDate,
      subtitle: data.Location
    }
  } else if(table === 'tblqKWImuUjMmUNU8') {
    return {
      name: data.Name,
      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
      subtitle: data.Community_Name,
      description: data.Description,
      link: data.Web_URL[0] === 'w' ? 'https://' + data.Web_URL : data.Web_URL,
      link_type: 'external',
      type: 'Chat'
    }
  } else if(table === 'tblLvLuIFUJSVd3QS') {
    return {
      name: data.Name,
      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
      description: data.Product_Categories?.join(" - "),
      link_type: 'internal',
      link: `/${lang}/businesses/${slugify(data.Name)}`,
      type: 'Business'
    }
  }
}
const infoKeys = {
  name: null,
  coordinates: null,
  color: null,
  table: null,
  type: null,
  description: null,

  pronunciation: null,
  pronunciation_audio: null,

  year: null,
  logo: null,
}
function otherTableKeys(data){
  return {
    communities: data.Communties || data.Community ? data.Community : null,
    firesides: data.Fireside_Chats || null,
    businesses: data.Businesses || null,
    videos: data.Videos || null,
    sites: data.Explore150_Sites || null,
  }
}

function linkKeys(data){
  let resources = [];
  let sources = [];
  if(data.Link_1) {
    resources.push({
      label: data.Link_1_Title || data.Link_1,
      link: data.Link_1
    })
    sources.push(data.Link_1);
  }
  if(data.Link_2) {
    resources.push({
      label: data.Link_2_Title || data.Link_2,
      link: data.Link_2
    })
    sources.push(data.Link_2);
  }
  if(data.Link_3) {
    resources.push({
      label: data.Link_3_Title || data.Link_3,
      link: data.Link_3
    })
    sources.push(data.Link_3);
  }
  if(data.Web_Site) {
    resources.push({
      label: 'Website',
      link: data.Web_Site
    })
  }
  if(data.Government_URL) {
    resources.push({
      label: 'Government Website',
      link: data.Government_URL
    })
    sources.push(data.Government_URL);
  }
  if(data.Community_URL) {
    resources.push({
      label: 'Community Website',
      link: data.Community_URL
    })
    sources.push(data.Community_URL);
  }
  if(data.Wikipedia_URL) {
    resources.push({
      label: 'Wikipedia Page',
      link: data.Wikipedia_URL
    })
    sources.push(data.Wikipedia_URL);
  }
  if(data.Field_6) {
    sources.push(data.Field_6);
  }
  if(data.Description_Source) {
    sources.push(data.Description_Source);
  }
  if(data.Community_Link) {
    sources.push(data.Community_Link);
  }
  if(data.CCAB_Profile_Link) {
    sources.push(data.CCAB_Profile_Link);
  }
  if(data.GeocodeURL) {
    sources.push(data.GeocodeURL);
  }
  return {
    resources,
    sources,
  }
  
}


function keyListing(table, data, lang){
  if(table === 'tblNbzH8fyCHl9Uak') {
    return {
      name: data.Treaty_Name_EN,
      table: 'treaties',
      coordinates: parsePolygonString(data.Coordinates),
      color: colors[data.ID % colors.length],
      description: data.Field_7,
      type: 'polygon',
      year: data.Year,
    }
  } else if(table === 'tblA1YdPobD3JN7iG') {
    const syl = data.Syllabics ? ' ' + data.Syllabics : '';    
    return {
      name: data.Name + syl,
      table: 'communities',
      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
     
      description: data.Description,
      type: 'marker',

      pronunciation: data.Pronunciation,
      pronunciation_audio: data.Pronunciation_Audio?.localFiles[0] || null,

      logo: data.Logo?.localFiles[0] || null,
    }
  } else if(table === 'tblD4d5rF383Bcbil') {   
    return {
      name: data.Name,
      table: 'territories',
      coordinates: parsePolygonString(data.Coordinates),
      color: data.Color,

      description: data.Description,
      type: 'polygon'
    }
  } else if(table === 'tblLvLuIFUJSVd3QS') {
    return {
      name: data.Name,
      table: 'businesses',
      address: data.Combined_Address,
      web_link: data.Web_Site,
      description: data.Description,
      link: `/${lang}/businesses/${slugify(data.Name)}`,
      email: data.E_mail_Address,
      categories: data.Product_Categories?.join(" - ") || null,
      ccab_link: data.CCAB_Profile_Link,
      phone: data.Phone_Number,
      tags: data.Keywords,

      coordinates: {lat: Number(data.Latitude), lng: Number(data.Longitude)},
      description: data.Description,
      type: 'marker',
      logo: data.Logo?.localFiles[0] || null,
    }
  }
}

export function keyMergeListing(table, data, lang){
  return {
    ...infoKeys,
    ...otherTableKeys(data),
    ...linkKeys(data),
    ...keyListing(table, data, lang)
  }
}